<template>
  <div class="dapp-item">
    <div class="m-title">
      <!-- 由PSC提供支持的工具和服务 -->
      {{ $t("dapp.box1T2") }}
    </div>
    <div class="m-p">
      <!-- 去中心化应用 (dapp)
        是在去中心化网络上构建的应用程序，结合了智能合约和前端用户界面。 -->
      {{ $t("dapp.box1P") }}
    </div>
    <div class="butBox">
      <a href="#dapp">
        <div class="m-but2">
          <!-- 探索DAPP -->
          {{ $t("dapp.box1But1") }}
        </div>
      </a>
      <br />
      <div class="m-but" @click="uti.goPath(store.link.dapp.box1Bt2)">
        <!-- 如何创建一个DAPP -->
        {{ $t("dapp.box1But2") }}
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dapp-item {
  background: url("../../assets/images/v4/dapp/m/box1Bg.png"),
    linear-gradient(0deg, #dae6f2, #edf4fc);
  // background-color: pink;
  background-position: center center, right bottom;
  background-size: (225 / 3.75vw) (207 / 3.75vw), 100% 100%;
  background-repeat: no-repeat;
  padding: (115 / 3.75vw) (14 / 3.75vw) (52 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  .m-p {
    margin-top: (21 / 3.75vw);
  }
  .butBox {
    margin-top: (51 / 3.75vw);
    .m-but {
      margin-top: (18 / 3.75vw);
    }
  }
}
</style>
