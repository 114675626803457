<template>
  <div class="dapp-box">
    <div class="m-title4" id="dapp">
      <!-- DAPP应用 -->
      {{ $t("dapp.box2T") }}
    </div>
    <div class="m-p">
      <!-- 一些polysmartchain.com团队现在喜欢的去中心化应用。下面是更多的去中心化应用。 -->
      {{ $t("dapp.box2ST") }}
    </div>
    <div class="box">
      <div
        class="m-item3"
        v-for="(item, index) in $t('home.box6ItemList')"
        :key="index"
      >
        <div class="img">
          <img
            :src="require(`../../assets/images/v4/home/box6${index + 1}.png`)"
            alt=""
          />
        </div>
        <div class="t">{{ item.title }}</div>
        <div class="link"></div>
        <div class="p">
          {{ item.text }}
        </div>
        <div class="more">
          <span @click="uti.goPath(store.link.home.box6List[index])">
            {{ item.ft }}
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-arrowRight"></use>
            </svg>
          </span>
          <div
            class="copy hash"
            v-if="store.link.home.box6Dapp[index]"
            @click="doCopy()"
            :data-clipboard-text="store.link.home.box6Dapp[index]"
          >
            <!-- {{ $t("dapp.copy") }} -->
            {{ store.link.home.box6Dapp[index] }}
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-copy"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {
    doCopy() {
      // console.log(cp);
      var clipboard = new Clipboard(".hash");
      // console.log(clipboard);
      clipboard.on("success", () => {
        this.$message.success("success");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制

        this.$message.error("error");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dapp-box {
  padding-top: (37 / 3.75vw);
  background-image: url("../../assets/images/v4/home/box2Bg.png");
  background-size: (225 / 3.75vw) (207 / 3.75vw);
  background-repeat: no-repeat;
  background-position: 0 60%, right 100%;
  text-align: center;
  .m-p {
    margin-top: (13 / 3.75vw);
  }

  .box {
    margin-top: 100px;
    // background-color: pink;
    width: 100%;
    // height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .m-item3 {
      min-height: (316.67 / 3.75vw);
      .img {
        margin-top: (25 / 3.75vw);
      }
      .t {
        margin-top: (0 / 3.75vw);
      }
      .link {
        margin: (5 / 3.75vw) auto;
      }
      .more {
        .copy {
          margin-right: (20 / 3.75vw);
          white-space: nowrap;
          opacity: 0.5;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
